<template>
	<div class="layout-page">
		<div class="summary-container">
			<div class="summary-actions">
				<FilterWrongTickets :sortBy="sortBy" :key="filterKey" :sortType="sortType" :changePagination="changePagination" :filterAgain="filterAgain" :pagination="pagination" @resetPagination="resetPagination" v-on:applyFilters="resetSelectedItems()" v-on:resetFilters="resetSelectedItems()"></FilterWrongTickets>
				<div class="table-actions">
					<div class="export">
						<div class="export-button" @click="exportButton = !exportButton">{{ selected.length > 0 ? $t('datatable.export') : $t('datatable.export_all') }}</div>
						<transition name="fade">
							<div class="export-box" v-if="exportButton">
								<!-- <div class="text" @click="downloadPDF()">{{ $t('bills.export_as_pdf') }}</div> -->
								<div class="text" @click="downloadEXCEL()">{{ $t('datatable.export_excel') }}</div>
								<!-- <div class="text" @click="downloadXML()">{{ $t('bills.export_as_xml') }}</div> -->
							</div>
						</transition>
					</div>
					<div class="search">
						<input type="text" v-model="search" class="input-search" name="name" />
					</div>
				</div>
			</div>
		</div>
		<div class="data-table">
			<v-data-table @toggle-select-all="allSelected = !allSelected" v-if="items" v-model="selected" :headers="headers" fixed-header :search="search" :items="Object.values(items)" sort-by="ticket_number" :single-select="singleSelect" :sort-asc="true" hide-default-footer show-select :options.sync="options" :page.sync="pagination.page" :items-per-page="pagination.itemsPerPage">
				<template #item.ticket_number="item">
					<div class="ticket">{{ '#' + item.item.ticket_number }}</div>
				</template>
				<template #item.ticket_date="item">
					<div class="row">{{ item.item.ticket_date }}</div>
				</template>
				<template #item.last_update="item">
					<div class="row">{{ item.item.last_update }}</div>
				</template>
				<template #item.try="item">
					<!-- <div class="row">{{ $t('wrong_tickets.try') }}</div> -->
					<div class="row actions">
						<div class="row-image" @click="showConfirmPopup(item.item)">
							<div class="retry">
								<!-- {{ $t('wrong_tickets.retry') }} -->
								<img class="icon" src="@/../public/img/reload.svg" alt="delete" />
							</div>
						</div>
					</div>
				</template>
				<template #item.restaurant_id="item">
					<div class="site" v-html="getRestaurant(item.item.restaurant_id)"></div>
				</template>
				<template #item.error="item">
					<div v-if="item.item.error.length > 0" class="inline" v-for="(error, index) in JSON.parse(item.item.error)" :key="index">
						<!-- {{ error }} -->
						<div class="ticket">
							{{ error.code + ' - ' }}
						</div>
						<div class="row">
							{{ error.error }}
						</div>
						<br />
					</div>
				</template>
				<!-- <template #item.file="item">
					<div class="row-image">
						<a target="_blank" :href="item.item.file"><img class="icon" src="../../public/img/pdf_icon.svg" /></a>
					</div>
				</template> -->
				<template v-slot:no-data>
					<div class="no-data">
						{{ $t('datatable.table_no_data') }}
					</div>
				</template>
			</v-data-table>
			<div id="pagination">
				<div class="totalItems">
					<span class="total">
						<strong class="total-results">{{ totalItems }}</strong>
						{{ $tc('datatable.results', totalItems) }}
					</span>
				</div>
				<v-pagination v-model="pagination.page" @input="changePage" color="#C4C4C4" :length="pageCount" :total-visible="maxPageVisibles"></v-pagination>
				<div class="rows-page">
					<v-select :items="rowsPerPage" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
				</div>
			</div>
		</div>
		<Modal v-if="showPopup" :hasConfirmButton="true" :description="$t('wrong_tickets.revalidate', { ticket: ticketNumber.ticket_number })" :cancelText="$t('modal.cancel')" v-on:close="closePopup()" v-on:confirm="revalidateTicket()"></Modal>
		<Modal v-else-if="isNotValid" :cancelText="$t('modal.return')" :errorType="errorType" :description="$t('wrong_tickets.error', { ticket: ticketNumber.ticket_number })" v-on:close="isNotValid = !isNotValid" v-on:confirm="revalidateTicket()"></Modal>
		<Modal v-else-if="isTicketValid" :description="$t('wrong_tickets.resend_valid', { ticket: ticketNumber.ticket_number })" :cancelText="$t('modal.return')" v-on:close="isTicketValid = !isTicketValid" v-on:confirm="revalidateTicket()"></Modal>
	</div>
</template>

<script>
import FilterWrongTickets from '@/components/wrongTickets/Filter.vue'
import Modal from '@/components/layout/wrongTickets/Modal.vue'
export default {
	components: { FilterWrongTickets, Modal },
	name: 'WrongTickets',
	data() {
		return {
			search: '',
			pagination: {
				page: 1,
				itemsPerPage: 10
			},
			allSelected: false,
			exportButton: false,
			singleSelect: false,
			valueFilter: 1,
			rowsPerPage: [10, 20, 50],
			selected: [],
			maxPageVisibles: 10,
			showPopup: false,
			ticketNumber: -1,
			isTicketValid: false,
			isNotValid: false,
			filterAgain: 1,
			errorType: false,
			options: {},
			sortBy: false,
			sortType: false,
			changePagination: false,
			filterKey: 1
		}
	},
	computed: {
		items() {
			const valid_tickets = this.$store.getters['wrongTickets/getWrongTickets']()
			let sortedTickets = []
			if (valid_tickets) {
				sortedTickets = Object.values(valid_tickets).sort((a, b) => {
					return a.date - b.date
				})
			}
			return sortedTickets
		},
		pageCount() {
			var pages = this.$store.getters['wrongTickets/getPageCount']
			if (this.pagination.page > pages) {
				this.pagination.page = 1
			}
			return pages
		},
		totalItems() {
			return this.$store.getters['wrongTickets/getTotalRows']
		},
		nRegistros() {
			var letter = this.$t('valid_tickets.all_valid_tickets')
			var nRegisters = this.pagination.itemsPerPage
			letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
			return letter
		},
		headers() {
			return [
				{
					text: this.$t('valid_tickets.number_site'),
					align: 'start',
					sortable: true,
					sort: this.sortBillNumber,
					value: 'restaurant_id',
					width: 'auto',
					class: 'header-table'
				},
				{
					text: this.$t('valid_tickets.number_ticket'),
					value: 'ticket_number',
					width: 'auto',
					sortable: true,
					class: 'header-table',
					sortable: true,
					sort: this.sortTicketNumber
				},
				{
					text: this.$t('wrong_tickets.error_type'),
					value: 'error',
					align: 'start',
					width: 'auto',
					class: 'header-table',
					sort: this.sortError
				},
				{
					text: this.$t('valid_tickets.date'),
					value: 'ticket_date',
					align: 'start',
					width: 'auto',
					class: 'header-table',
					sortable: true,
					sort: this.sortDate
				},
				{
					text: this.$t('valid_tickets.date_updated'),
					value: 'last_update',
					align: 'start',
					width: 'auto',
					class: 'header-table',
					sortable: true,
					sort: this.sortLastUpdate
				},
				{
					text: '',
					value: 'try',
					align: 'start',
					width: 'auto',
					sortable: true,
					class: 'header-table'
				}
			]
		},
		// pages() {
		// 	if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
		// 		return 1
		// 	}

		// 	return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
		// },
		customers() {
			var tempResult = {}
			if (this.items) {
				var arr = Object.values(this.items)

				for (let { customer_fiscal_id } of arr)
					tempResult[customer_fiscal_id] = {
						customer_fiscal_id,
						count: tempResult[customer_fiscal_id] ? tempResult[customer_fiscal_id].count + 1 : 1
					}

				let result = Object.values(tempResult)

				return result
			}
			return tempResult
		}
	},
	methods: {
		sortTicketNumber(a, b) {
			if (a < b) {
				return -1
			}
			return 1
		},

		sortError(a, b) {
			if (a < b) {
				return -1
			}
			return 1
		},

		sortDate(a, b) {
			if (a < b) {
				return -1
			}
			return 1
		},

		sortLastUpdate(a, b) {
			if (a < b) {
				return -1
			}
			return 1
		},

		sortBillNumber(a, b) {
			if (a < b) {
				return -1
			}
			return 1
		},

		revalidateTicket() {
			this.$overlay.show()
			console.log(this.ticketNumber)
			var self = this
			this.$store
				.dispatch('wrongTickets/revalidateTicket', { ticket_id: self.ticketNumber.id })
				.then((response) => {
					console.log(response)
					if (!response.status) {
						// self.$overlay.hide()
						self.showPopup = false
						self.isNotValid = true
						self.errorType = response.error
						self.$store.commit('wrongTickets/setErrorType', { id: self.ticketNumber.id, error: response.error })
					} else {
						// self.$overlay.hide()
						self.showPopup = false
						self.isTicketValid = true
						self.$store.commit('wrongTickets/deleteTicket', self.ticketNumber.id)
						self.$store.commit('validTickets/addTicket', self.ticketNumber)
						// Filtrar la tabla de nuevo para que desaparezca el ticket
					}
					self.$store.dispatch('wrongTickets/loadFiltersWrongTickets').then(function () {
						self.filterAgain++
						self.$overlay.hide()
					})
				})
				.catch(() => {
					self.$overlay.hide()
				})
		},
		changePage() {
			this.changePagination = true
		},
		resetPagination() {
			this.selected = []
			this.changePagination = false
			this.pagination.page = 1
		},
		showConfirmPopup(id) {
			this.showPopup = true
			this.ticketNumber = id
		},
		closePopup() {
			this.showPopup = false
			this.ticketNumber = -1
		},
		getRestaurant(id) {
			let restaurant = this.$store.getters['restaurant/getRestaurants'](id)
			if (restaurant) {
				return '<b>' + restaurant.code + '</b> - ' + restaurant.name
			}
			return ''
		},
		load() {
			// this.$store.dispatch('init')
			this.$store.dispatch('wrongTickets/loadFiltersWrongTickets').then(function () {})
		},
		restaurants(id) {
			if (id) {
				var restaurant = this.$store.getters['restaurant/getRestaurants'](id)
				if (restaurant) {
					return restaurant
				}
			}
		},
		downloadPDF() {
			var parameters = {}
			const filtersActive = this.$store.getters['bills/getBillsFiltersActive']

			if (Object.values(this.selected) == 0 || this.allSelected) {
				parameters.all = 1
			} else {
				var ids = []
				for (var bill in Object.values(this.selected)) {
					var idx = Object.values(this.selected)[bill]
					if (idx) {
						ids.push(idx.id)
					}
				}
				parameters.bills = ids.toString()
			}

			if (filtersActive.customers != 'admin.filters.all_customers') {
				parameters.customers = filtersActive.customers
			}

			if (typeof filtersActive.date !== 'undefined') {
				parameters.date = filtersActive.date
			}

			if (typeof filtersActive.start_date !== 'undefined') {
				parameters.start_date = filtersActive.start_date
			}

			if (typeof filtersActive.end_date !== 'undefined') {
				parameters.end_date = filtersActive.end_date
			}

			if (filtersActive.restaurants != 'admin.filters.all_restaurants') {
				parameters.restaurants = filtersActive.restaurants
			}

			this.$overlay.show()
			const self = this
			this.$store.dispatch('bills/downloadPDF', parameters).then(function (response) {
				self.exportButton = false
				self.$overlay.hide()
				window.open(response, 'blank')
			})
		},
		downloadEXCEL() {
			var parameters = {}
			const filtersActive = this.$store.getters['wrongTickets/getWrongTicketsFiltersActive']

			if (Object.values(this.selected) == 0 || this.allSelected) {
				parameters.all = 1
			} else {
				var ids = []
				for (var ticket in Object.values(this.selected)) {
					var idx = Object.values(this.selected)[ticket]
					if (idx) {
						ids.push(idx.id)
					}
				}
				parameters.tickets = ids.toString()
			}

			// if (filtersActive.customers != 'admin.filters.all_customers') {
			// 	parameters.customers = filtersActive.customers
			// }

			if (typeof filtersActive.date !== 'undefined') {
				parameters.date = filtersActive.date
			}

			if (typeof filtersActive.start_date !== 'undefined') {
				parameters.start_date = filtersActive.start_date
			}

			if (typeof filtersActive.end_date !== 'undefined') {
				parameters.end_date = filtersActive.end_date
			}

			if (filtersActive.id_sites != 'filters.all_restaurants') {
				parameters.restaurants = filtersActive.id_sites
			}

			parameters.type = 3

			this.$overlay.show()

			const self = this
			this.$store.dispatch('wrongTickets/downloadEXCEL', parameters).then(function (response) {
				self.exportButton = false
				self.$overlay.hide()
				window.open(response, 'blank')
			})

			this.$store.dispatch('bills/downloadEXCEL', parameters).then(function (response) {
				self.exportButton = false
				self.$overlay.hide()
				window.open(response.data, 'blank')
			})
		},
		downloadXML() {
			const parameters = {}
			const filtersActive = this.$store.getters['bills/getBillsFiltersActive']

			if (Object.values(this.selected) == 0 || this.allSelected) {
				parameters.all = 1
			} else {
				const ids = []

				for (let bill in Object.values(this.selected)) {
					const idx = Object.values(this.selected)[bill]

					if (idx) {
						ids.push(idx.id)
					}
				}

				parameters.bills = ids.toString()
			}

			if (filtersActive.customers != 'admin.filters.all_customers') {
				parameters.customers = filtersActive.customers
			}

			if (typeof filtersActive.date !== 'undefined') {
				parameters.date = filtersActive.date
			}

			if (typeof filtersActive.start_date !== 'undefined') {
				parameters.start_date = filtersActive.start_date
			}

			if (typeof filtersActive.end_date !== 'undefined') {
				parameters.end_date = filtersActive.end_date
			}

			if (filtersActive.restaurants != 'admin.filters.all_restaurants') {
				parameters.restaurants = filtersActive.restaurants
			}

			this.$overlay.show()

			const self = this
			this.$store.dispatch('bills/downloadXML', parameters).then((response) => {
				self.exportButton = false
				self.$overlay.hide()
				window.open(response, 'blank')
			})
		},

		sortBillNumber(elementA, elementB) {
			let result = 0

			const elementATicketNumber = elementA.split('MCD')
			const elementBTicketNumber = elementB.split('MCD')

			elementATicketNumber[0] = parseInt(elementATicketNumber[0])
			elementATicketNumber[1] = parseInt(elementATicketNumber[1])

			elementBTicketNumber[0] = parseInt(elementBTicketNumber[0])
			elementBTicketNumber[1] = parseInt(elementBTicketNumber[1])

			if (elementATicketNumber[0] > elementBTicketNumber[0]) {
				result = -1
			}

			if (elementATicketNumber[0] < elementBTicketNumber[0]) {
				result = 1
			}

			if (elementATicketNumber[0] == elementBTicketNumber[0]) {
				if (elementATicketNumber[1] > elementBTicketNumber[1]) {
					result = -1
				}

				if (elementATicketNumber[1] < elementBTicketNumber[1]) {
					result = 1
				}
			}

			return result
		},
		resetSelectedItems() {
			this.selected = []
			this.exportButton = false
		}
	},
	created() {
		this.load()
	},
	watch: {
		options: {
			handler() {
				const { sortBy, sortDesc, page, itemsPerPage } = this.options
				this.sortBy = typeof sortBy[0] !== 'undefined' ? sortBy[0] : false
				this.sortType = typeof sortDesc[0] !== 'undefined' ? (sortDesc[0] ? 'desc' : 'asc') : false
				this.filterKey++
			},
			deep: true
		}
	}
}
</script>

<style lang="scss">
.search {
	.input-search {
		background-image: url('../../public/img/searcher.svg');
	}
}

.data-table {
	.text-start {
		.inline {
			display: contents !important;

			.ticket {
				width: fit-content;
				display: contents !important;
			}

			.row {
				display: contents !important;
			}
		}
	}
}
</style>
